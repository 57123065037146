.Body {
    text-align: center;
}

body {
    background-image: url('/public/sitebanner.jpg'); /* Replace 'background.jpg' with your actual background image path */
    background-size: auto;
    background-position: center;
    background-repeat: no-repeat;
}

.App-logo {
    height: 8vmin;
    pointer-events: none;
}

.portrait {
    margin-right: 20px;
    display: flex;
    align-items: center;
}

    .portrait img {
        max-width: 200px; /* Set maximum width */
        max-height: 200px; /* Set maximum height */
        width: auto;
        height: auto;
        border-radius: 50%;
        overflow: hidden;
    }

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        /*animation: App-logo-spin infinite 20s linear;*/
    }
}


.logo {
    display: flex;
    align-items: center;
    text-decoration: none;
    max-width: 800px;
    margin: auto;
    text-align: center;
}

.logo-image {
    height: 40px;
    margin-right: 10px;
    text-decoration: none;
}

.logo-text {
    font-size: 24px;
    color: black;
    text-decoration: none;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

header {
    /*background: linear-gradient(to bottom, #6dcff6, #ffffff); /* Light blue to white gradient */
    background: linear-gradient(to bottom, #0f222d, #ffffff); /* Light blue to white gradient */
    color: white;
    padding: 5px;
    
}

    header nav ul {
        list-style-type: none;
        padding: 0;
    }

        header nav ul li {
            display: inline;
            margin-right: 0px;
        }

footer {
    background: linear-gradient(to top, #0f222d, #ffffff);
    color: black;
    padding: 10px;
    margin-top: 5px;
    position: fixed;
    bottom: 0;
    width: 100%;
}

.container-header {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    display: inline;
}

.rows .row {
    display: inline-block;
}

.container {
    max-width: 800px;
    margin: auto;
    background: white;
    padding: 10px 20px;
    margin-bottom: 10px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-height: 70vh;
    overflow-y: auto;
}

button {
    color: antiquewhite;
    padding: 5px 15px;
    border-radius: 5px;
    outline: 0;
    border: 0;
    font-weight: bold;
    margin: 10px 0px;
    cursor: pointer;
    box-shadow: 0px 2px 2px lightgray;
    transition: ease background-color 250ms;
    background: linear-gradient(to top, #0f222d, #ffffff);
}

    button.menuItem {
        color: antiquewhite;
        padding: 5px 15px;
        border-radius: 0px;
        outline: 0;
        border: 0;
        font-weight: bold;
        margin: 10px 0px;
        cursor: pointer;
        box-shadow: 0px 2px 2px lightgray;
        transition: ease background-color 250ms;
        background: linear-gradient(to top, #0f222d, #cccccc);
    }
    button:hover {
        background-color: #005bb5;
        transform: scale(1.05);
    }
h1 {
    color: #0066cc;
    text-align: center;
}

h2 {
    color: #003366;
    margin-top: 20px;
}

ul {
    margin: 10px 0;
    padding-left: 20px;
}

    ul.menuItem {
        padding: 0px;
    }

.build {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

    .build img {
        max-width: 100%;
        border-radius: 8px;
    }

.build-info {
    flex: 1;
    padding: 10px 20px;
}

    .build-info h2 {
        color: #003366;
    }

    .build-info p {
        margin-bottom: 10px;
    }

.build-gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.build-card {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 300px;
    margin: 10px;
}

    .build-card img {
        width: 100%;
        height: auto;
    }

    .build-card .build-card-content {
        padding: 15px;
    }

        .build-card .build-card-content h3 {
            color: #003366;
            margin-top: 0;
        }

        .build-card .build-card-content p {
            margin-bottom: 10px;
        }

.profile {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

    .profile img {
        max-width: 100%;
        border-radius: 8px;
    }

.profile-info {
    flex: 1;
    padding: 10px 20px;
}

    .profile-info h2 {
        color: #003366;
    }

    .profile-info p {
        margin-bottom: 10px;
    }

    .profile-info ul {
        padding-left: 20px;
    }

        .profile-info ul li {
            margin-bottom: 5px;
        }


        fill{
            display: flex;
            width: 100%;
        }

.cta {
    padding-bottom: 50px;
}